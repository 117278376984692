// Main
$(document).ready( function () {
  var navbar = '.navbar',
      $navbar = $(navbar),
      toggler = $('.navbar-toggler'),
      collapseShow = 'navbar-collapse-show';
  toggler.on( "click", function() {
    if($navbar.hasClass(collapseShow)) {
      $navbar.removeClass(collapseShow);
    } else {
      $navbar.addClass(collapseShow);
    }
  });
  // Navbar scroll
  var scrollTop = 0;
  $(window).scroll(function() {
    scrollTop = $(window).scrollTop();
    $('.counter').html(scrollTop);
    if (scrollTop >= 45) {
      $navbar.addClass('navbar-scroll');
    } else if (scrollTop < 45) {
      $navbar.removeClass('navbar-scroll');
    }
  });
  // datatables init
  $('.datatable').DataTable();
});

// Tooltip
$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

// Button: Collapse toggle material design icon arrow
$('.btn-collapse').on('click', function() {
  $(this).children('i').toggleText('keyboard_arrow_left', 'keyboard_arrow_down');
});

// jQuery Extend: Toggle Text
$.fn.extend({
  toggleText: function(a, b) {
    return this.text(this.text() == b ? a : b);
  }
});

// dataTables default config
$.extend( true, $.fn.dataTable.defaults, {
  "dom":  '<"d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-between"' +
            '<"search mb-2"f><"lenght mb-2"l>'+
          '>' +
            '<"table-responsive my-2"tr>' +
          '<"d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between"' +
            '<"pagination mb-2"p><"small text-muted mb-2"i>'+
          '>',
  "language": {
    "url":  "//cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
  }
});
