// AOS
AOS.init({
  duration: 1000
});

var scroll = new SmoothScroll('[data-scroll]', {
  speed: 1500,
  speedAsDuration: true,
  header: '.navbar',
  offset: 0,
});

$(document).ready(function(){
  $('.geometryangle').Geometryangle({
    mesh: {
      width: 2,
      height: 2,
      columns: 8,
      rows: 8,
      depth: 20,
      ambient: 'rgba(85, 85, 85, 1)',
      diffuse: 'rgba(255, 255, 255, 1)',
      background: 'rgba(20, 35, 65, 1)',
      fluctationSpeed: .00001,
      fluctationIntensity: 0
    },
    lights: [{
      draw: true,
      count: 1,
      xyScalar: 0,
      zOffset: 100,
      ambient: 'rgba(20, 35, 65, 1)',
      diffuse: 'rgba(24, 43, 78, 1)',
      speed: 1,
      position: [1000, 100]
    }],
    line: {
      fill: 'rgba(20, 35, 65, 1)',
      thickness: 5,
      draw: false
    },
    vertex: {}
  });
});

// Blog Image Wrapper
$.fn.imgWrap = function() {
  var thisClass = this.attr('class');
  this.wrap('<div class="img '+ thisClass +'"></div>');
  this.removeClass();
}
$('.page-post img:not(.cocoen-img').each(function() {
  $(this).imgWrap();
});

// Cotizador
cotizadorStep = 1;
cotizadorSubStep = null;
cotizadorProgress = 12.5;
inSubStep = false;
$('#modalCotizador button.option, #modalCotizador button.sub-option').on('click', function() {
  if(inSubStep == false && $(this).data('sub-step') != undefined) {
    cotizadorSubStep = $(this).data('sub-step');
  }
  if(cotizadorSubStep === null || inSubStep === true) {
    if(inSubStep) {
      $('.step-' + cotizadorSubStep).removeClass('show').addClass('hide');
      $('.step-' + (cotizadorStep+1)).removeClass('hide').addClass('show');
      inSubStep = false;
      cotizadorSubStep = null;
      cotizadorStep++;
    } else {
      $('.step-' + cotizadorStep).removeClass('show').addClass('hide');
      $('.step-' + (cotizadorStep+1)).removeClass('hide').addClass('show');
      cotizadorSubStep = null;
      cotizadorStep++;
    } 
    if(cotizadorStep > 1) {
      $('button.btn-back').fadeIn();
    }
  } else {
    $('.step-' + cotizadorStep).removeClass('show').addClass('hide');
    $('.step-' + cotizadorSubStep).removeClass('hide').addClass('show');
    $('button.btn-back').fadeIn();
    inSubStep = true;
  }
  if($(this).data('sub-step') === undefined) {
    cotizadorProgress += 12.5;
    $('#cotizadorStep span').text(cotizadorStep);
    $('#cotizadorProgress').css('width', cotizadorProgress + '%').attr('aria-valuenow', cotizadorProgress);
  }
});

$('#modalCotizador button.btn-back').on('click', function() {
  if(cotizadorStep >= 2 && cotizadorSubStep === null) {
    $('.step-' + cotizadorStep).removeClass('show').addClass('hide');
    $('.step-' + (cotizadorStep-1)).removeClass('hide').addClass('show');
    cotizadorStep--;
    if(cotizadorStep === 1 ) {
      $(this).fadeOut();
    }
  } else if(cotizadorStep >= 1) {
    $('.step-' + cotizadorSubStep).removeClass('show').addClass('hide');
    $('.step-' + cotizadorStep).removeClass('hide').addClass('show');
    cotizadorSubStep = null;
    inSubStep = false;
    if(cotizadorStep === 1 ) {
      $(this).fadeOut();
    }
  }
  if(cotizadorProgress > 12.5 && $(this).data('sub-step') === undefined) {
    cotizadorProgress -= 12.5;
    $('#cotizadorStep span').text(cotizadorStep);
    $('#cotizadorProgress').css('width', cotizadorProgress + '%').attr('aria-valuenow', cotizadorProgress);
  }
});


$('#modalCotizador button.option-select').on('click', function() {
  $(this).toggleClass('active').attr('aria-pressed', function (i, attr) {
    return attr == 'true' ? 'false' : 'true'
  }).button('refresh');
});

$('#modalCotizador button.btn-done').on('click', function() {
  cotizadorStep = 1;
  cotizadorSubStep = null;
  cotizadorProgress = 12.5;
  inSubStep = false;
  $('#cotizadorStep span').text(cotizadorStep);
  $('#cotizadorProgress').css('width', cotizadorProgress + '%').attr('aria-valuenow', cotizadorProgress);
  $('.step-8').removeClass('show').addClass('hide');
  $('.step-1').removeClass('hide').addClass('show');
  $('#modalCotizador button.option-select').each(function() {
    $(this).removeClass('active');
  });
});

// Cocoen: Before and After Image Slider
$('.cocoen').cocoen();

